.fade-in-left {
    animation: fadeInLeft 3s;
    -webkit-animation: fadeInLeft 3s;
    -moz-animation: fadeInLeft 3s;
    -o-animation: fadeInLeft 3s;
    -ms-animation: fadeInLeft 3s;
  }

  @keyframes fadeInLeft {
    0% {
       opacity: 0;
       transform: translateX(-20px);
    }
    100% {
       opacity: 1;
       transform: translateX(0);
    }
 }   
  
  @-moz-keyframes fadeInLeft {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-webkit-keyframes fadeInLeft {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-o-keyframes fadeInLeft {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-ms-keyframes fadeInLeft {
    0% {opacity:0;}
    100% {opacity:1;}
  }

  .fade-in {
    animation: fadeInLeft 3s;
    -webkit-animation: fadeInLeft 3s;
    -moz-animation: fadeInLeft 3s;
    -o-animation: fadeInLeft 3s;
    -ms-animation: fadeInLeft 3s;
  }

  @keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: 1;}
 }   
  
  @-moz-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-webkit-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-o-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-ms-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }

  .fade-in-right {
    animation: fadeInLeft 3s;
    -webkit-animation: fadeInLeft 3s;
    -moz-animation: fadeInLeft 3s;
    -o-animation: fadeInLeft 3s;
    -ms-animation: fadeInLeft 3s;
  }

  @keyframes fadeInRight {
    0% {
       opacity: 0;
       transform: translateX(20px);
    }
    100% {
       opacity: 1;
       transform: translateX(0);
    }
 }   
  
  @-moz-keyframes fadeInRight {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-webkit-keyframes fadeInRight {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-o-keyframes fadeInRight {
    0% {opacity:0;}
    100% {opacity:1;}
  }

  .fade-in-top {
    animation: fadeInTop 3s;
    -webkit-animation: fadeInTop 3s;
    -moz-animation: fadeInTop 3s;
    -o-animation: fadeInTop 3s;
    -ms-animation: fadeInTop 3s;
  }

  @keyframes fadeInTop {
    0% {
       opacity: 0;
       transform: translateY(20px);
    }
    100% {
       opacity: 1;
       transform: translateY(0);
    }
 }   
  
  @-moz-keyframes fadeInTop {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-webkit-keyframes fadeInTop {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-o-keyframes fadeInTop {
    0% {opacity:0;}
    100% {opacity:1;}
  }

  .fade-in-bottom {
    animation: fadeInLeft 3s;
    -webkit-animation: fadeInLeft 3s;
    -moz-animation: fadeInLeft 3s;
    -o-animation: fadeInLeft 3s;
    -ms-animation: fadeInLeft 3s;
  }

  @keyframes fadeInBottom {
    0% {
       opacity: 0;
       transform: translateY(-20px);
    }
    100% {
       opacity: 1;
       transform: translateY(0);
    }
 }   
  
  @-moz-keyframes fadeInBottom {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-webkit-keyframes fadeInBottom {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-o-keyframes fadeInBottom {
    0% {opacity:0;}
    100% {opacity:1;}
  }