.IconsList{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.IconsList *{
    flex: 1;
}

.Icon{
    width: 2.5rem;
}

.IconsList img{
    width: 100%;
}

