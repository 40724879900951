.Home{
  color: white;
  /* haut | horisontal | bas */
  margin: 2vh 2rem 0;
}

.Half-div{
  flex: 1;
  margin: 1rem;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.link:hover{
  color: #B27993;
}

.currentSituation{
  padding-top: 0.5rem;
  padding-left: 1.5rem;
  text-align: left;
}

.color-title{
  color: #B27993;
}

/* Largeur maximale */
@media (max-width: 1000px) {
  .Half-div{
    width:95%;
    display: flex;
    /* haut | droit | bas | gauche */
    margin: 1rem;
    flex-direction: column;
  }

  .currentSituation{
    padding-left: 1.5rem;
    text-align: left;
  }

  .Home{
    margin-top : 1rem;
    margin-left: 0rem;
  }
}