.resumeLink:hover{
    color: #443449;
}
.RowSquaresSection {
    min-height: 78vh;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 6%;
}

.Section{
    padding: 1.5rem;
    text-align: center;
    background-color: #7e4e68; /* à changer */
    color: white;
    min-height: 58vh;
    display:flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 1rem;
}

.Section-title{
    height: 20%;
}

.Section-content{ 
    height: 80%;
}

.PresentationSection-section{
    text-align: center;
    width: 45%;
    height: auto;
    vertical-align: middle;
    display: inline-block;
    flex: 1;
    flex-wrap: wrap;
}

.PresentationSection-section-p{
    text-align: justify;
}

.PresentationSection-img{
    width:70%;
    border-radius: 50%;
    border: solid 0.5rem #443449;
}

.PresentationSection-content{
    height: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.ThirdSection{
    text-align: center;
    background-color: #443449;
    margin-bottom: 5%;
    color: white;
    min-height: 27vh;
    width: 100%;
    padding: 1%;
    display:flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    border-radius: 1rem;
}

/* Largeur maximale */
@media (max-width: 1100px) {
    .ThirdSection {
        height: auto;
        display: block;
        padding: 1rem;
    }
  }

/* Largeur maximale */
@media (max-width: 990px) {
    .PresentationSection-content{
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;
    }

    .Section {
        height: auto;
    }

    .RowSquaresSection {
        height: auto;
        margin-top: 0rem;
        margin-left: 2rem;
        margin-right: 2rem;
    }
    .PresentationSection-section{
        width: 100%;
    }
  }
  @media (max-width: 650px) {
  .RowSquaresSection {
    height: auto;
    margin-top: 0rem;
    flex-direction: column;
}
  }