*{
	box-sizing: border-box;
}
h3{
	font-weight: bold;
}

.spaced{
	margin-top: 1.5rem;
	padding-top: 1.5rem;
	border-top: solid #443449;
}

.limit{
	border-top: solid 2px grey;
	padding-top: 1em;
}

/* vertical line center */
.timeline{
	position: relative;
	max-width:75rem;
	margin: 0 auto;
    color: white;
}

.timeline::after{
	content: '';
	position: absolute;
	width: 0.375rem;
	background-color: #B27993;
	top: 0;
	bottom: 0;
	left: 50%;
	margin-left: -3px;
}

/* Around content*/
.timelineContainer{
	padding: 0.625rem 2.5rem;
	position: relative;
	width: 50%;
}

/*Circle*/
.timelineContainer::after{
	content: '';
	position: absolute;
	width: 0.938rem;
	height: 0.938rem;
	right: -1%; /* position du rond sue l'axe des ordonnées*/
	background-color: #443449;
	border: 2px solid #B27993;
	top: 1.438rem;
	border-radius: 50%;
	z-index: 1;
}

/*Circle for right elements*/
.right::after{
	left: -0.2rem;
}

/* Put elements at the left*/
.left{
	left: 0%;
}
/*Put elements at the right*/
.right{
	left: 49.7%;
}

/* mouse over element*/

/*change circle color*/
.timelineContainer:hover::after{
	background-color: #7E4E68;
	border: 2px solid #B27993;
}

/*on change l'arrière plan et le couleur de police*/
.timelineContainer:hover .timelineContent{
	border: medium solid #B27993;
}

/* on change la couleur des flèches */
.left:hover::before{
	border-color: transparent transparent transparent #B27993;
}

.right:hover::before{
	border-color: transparent #B27993 transparent transparent;
}

/* Pour faire les flèches qui pointent à droite*/
.left::before{
	content: " ";
	height: 0;
	position: absolute;
	top: 1rem;
	width: 0;
	z-index: 1;
	right: 1.5rem;
	border: medium solid #7E4E68;
	border-width: 1rem 0 1rem 1rem;
	border-color: transparent transparent transparent #7E4E68;
}

/* Dans l'autre sens */
.right::before{
	content: " ";
	height: 0;
	position: absolute;
	top: 1rem;
	width: 0;
	z-index: 1;
	left: 1.5rem;
	border: medium solid #7E4E68;
	border-width: 1rem 1rem 1rem 0;
	border-color: transparent #7E4E68 transparent transparent;
}

/* Le contenu actuel */
.timelineContent{
	padding: 1.25rem 1.875rem;
	background-color: #7E4E68;
	position: relative;
	border-radius: 0.375rem;
    border: medium solid #7E4E68;
}

a{
	color: white;
}

iframe {
	width: 100%;
}


/* pour le responsive */
@media screen and (max-width: 990px){
	/* mettre la barre à gauche*/
	.timeline::after{
		left:0rem;
	}
	
	.timelineContainer{
		width: 100%;
		padding-left: 30px;
		padding-right: 25px;
		border-color: #B27993;
	}
	
	.timelineContainer::before{
		left: 0rem;
		border:medium solid #B27993;
		border-width: 10px 10px 10px 0;
		border-color: transparent #B27993 transparent transparent;
	}

	.timelineContent{
		border-color: #B27993;
		left: -0.8rem;
		padding: 1rem;
		overflow: scroll;
	}
	
	/* plus de trucs à gauche ou a droite, tout du même coté */
	.left::after, .right::after{
		left:-0.5rem;
	}
	.right{
		left: 0;
	}
	main h2{
		font-size: 1em;
	}
	main p {
		font-size: 0.7em;
	}
	
	.left::before, .right::before{
	content: " ";
	height: 0;
	position: absolute;
	top: 1.25rem;
	width: 0;
	z-index: 1;
	left: 0.5rem;
	border: medium solid #B27993;
	border-width: 10px 10px 10px 0px;
	border-color: transparent #B27993 transparent transparent;
	}

	.left:hover::before, .right:hover::before{
		border: medium solid #B27993;
		border-width: 10px 10px 10px 0px;
		border-color: transparent #B27993 transparent transparent;
	}

	.right:hover{
		left: 0%;
	}
}

@media screen and (max-width: 400px){
	iframe {
		display: none;
	}

	h2{
		font-size: 1rem;
	}
    h4{
		font-size: 1rem;
	}
	.timelineContainer{
		padding-right: 0px;
	}
}