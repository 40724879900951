.PicturesSection{
    text-align: center;
    margin: 0 10%;
    color: white;
    height: 100%;
    display:flex;
    align-items: center;
    border-radius: 1rem;
     width: 70%; /* Nomralement il n'y en a pas besoin car mes images sont toutes dans le bon sens*/ 
}

.CarouselImg{
    max-height: 29vh;
    width: 100%; /* à changer si les images sont déformées */
    overflow: hidden;
    
}

.Carousel{
    height: 30vh;
    display: flex;
    align-items: center;
}

@media (max-width: 990px) {
    .Carousel{
        height: 25vh;
    }
    .CarouselImg{
        max-height: 24vh;
    }
    .PicturesSection{
        margin: 0;
        width: 100%;
    }
  }