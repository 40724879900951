.IconSection{
    text-align: center;
    color: #ededed;
    display:flex;
    flex-direction: column;
    align-items: center;
    border-radius: 1rem;
    flex: 1;
    margin : 0 5%;
    padding: 0.5rem;
}

.IconSection:hover{
    transform: scale(1.25);
    transition: 150ms ease
}

.IconSection-div{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.IconSection-div img{
    max-width: 5rem;
    vertical-align: middle;
    width: 100%;
}

/* Largeur maximale */
@media (max-width: 1100px) {
    .IconSection-div{
        width: 100%;
    }
    .IconSection-div img{
        max-width: 4rem;  
    }
  }
