.hudge{
    font-size: 15rem;
}

.half{
    flex: 1;
}

.notFoundContent{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    border-top: solid white;
    margin : 2rem 10rem;
    padding : 5rem;
}

.notFound{
    height: 84.5vh;
    margin: 0;
    background-size: cover;
    background-repeat: no-repeat;
}

.backButton{
    background-color: #B27993;
    border: solid white;
    border-radius: 1rem;
}

.backButton:hover{
    background-color: white;
    border: solid #B27993;
    border-radius: 1rem;
    color: #B27993;
}

@media (max-width: 1000px) {
    .hudge{
        font-size: 10rem;
    }
    
    .half{
        flex: 1;
    }
    
    .notFoundContent{
        margin : 2rem 5rem;
        padding : 4rem;
    }
  }

  @media (max-width: 550px) {
    .hudge{
        font-size: 5rem;
    }
    
    .half{
        flex: 1;
    }
    
    .notFoundContent{
        margin : 2rem;
        padding : 2rem;
    }

    .notFound{
        padding-top: 2.5rem;
    }

  }