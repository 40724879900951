.Projects {
   margin: 1rem;
}

.ProjectTile {
    display: flex;
    flex-direction : column;
    border-top-style: solid;
    border-color: grey; /*Couleur à revoir*/
    margin: 1% 3%;
}

.ProjectTileContent {
    display: flex;
    flex-direction : row;
    flex-wrap: wrap;
}

.ProjectTileContent  *{
    flex: 1;
    list-style-type: disc;
    text-align: justify;
}

.ProjectTileTitle {
    padding: 1rem 2rem;
}

@media (max-width: 990px) {
    .ProjectTileContent {
        flex-direction : column;
        display: block;
    }
  }