.Contact {
  margin: 1rem;
}

.ContactForm{
    color: white;
    text-align: start;
    margin : 3% 10%;
    padding: 5%;
    background-color: #443449;
    border-radius:  1rem;
}

.ContactButton{
    background-color: #B27993;
    border: medium solid white;
    border-radius: 1rem;
    color: white;
}

.ContactButton:hover{
    background-color: white;
    border: medium solid white;
    color: #7E4E68;
}
.ContactButton:disabled{
  background-color: #7E4E68;
    border: medium solid white;
    border-radius: 1rem;
    color: white;
}

.ContactButton:active{
  background-color: white;
    border: medium solid white;
    color: #7E4E68;
    cursor: wait;
}

.ContactButton:focus{
  background-color: white;
    border: medium solid white;
    color: #7E4E68;
    cursor: wait;
}
  #formBasicCheckbox {
    font-family: "Font Awesome 5 Free";
    content: '\f0c8';
    font-size: 1.2em;  
    background-color: white;
    margin-right: 0.3rem  ;
    border-color:white;
  }
  #formBasicCheckbox:checked {
    content:'\f14a';
    color: #B27993;
    background-color: #B27993;
    border-color:white;
  }

  .linkedIn{
    text-decoration: none;
  }

  .linkedIn:hover{
    text-decoration: underline;
    color: white;
  }