.Skills{
    text-align: center;
    color: white;
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    border-radius: 1rem;
    width: 100%;
}

.Skills div{
    width: 6rem;
}