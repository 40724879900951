.noDecoration {
    text-decoration : none;
}

.Footer {
    height: auto;
    position: relative;
    bottom: 0;
    background-color: #0e1b2b;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.FooterMain{
    flex: 3;
}
.FooterBtn{
    flex: 1;
}

.FooterBtn:hover{
    text-decoration: underline;
    cursor: pointer;
}


@media (max-width: 400px) {
    .Footer {
        font-size: 0.85rem;
    }
  }