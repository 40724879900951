
.Layout {
    text-align: center;
    background-color : #1d2436;
    color: white;
    min-height: 100vh;
}

.content{
    padding-top: 7vh;
    min-height: 91.5vh;
}

body{
    background-color : #1d2436;
    overflow-x: hidden;
    scroll-behavior:smooth;
    min-height: 100vh;
  }

  @media (max-height: 645px) {
    .content{
        min-height: 88.5vh;
    }
  }

 /*

 html {
--scrollbarBG: #d4f80a;
--thumbBG: #ec0bec;
}
body::-webkit-scrollbar {
width: 10px;
}
body {
scrollbar-width: large;
scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
body::-webkit-scrollbar-track {
background: var(--scrollbarBG);
}
body::-webkit-scrollbar-thumb {
background-color: var(--thumbBG) ;
border-radius: 6px;
border: 3px solid var(--scrollbarBG);
}



 html {
background: lightgrey;
overflow: hidden;
}
body {
height: 100vh;
background: whitesmoke;
overflow-y: auto;
max-width: 310px;
margin: 0 auto;
padding: 2rem;
font: 100%/1.4 serif;
border: 1px solid rgba(0, 0, 0, 0.25)
}
p {
margin: 0 0 1.5em;
}
* {
box-sizing: border-box;
}*/

  