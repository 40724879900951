.DropdownTile {
    background-color: #443449;
    border-radius: 1rem;
    padding: 1rem;
    text-align: start;
    color: white;
    margin: 1rem;
}

.DropdownHeader:hover{
    cursor: pointer;
}

.DropdownArrow{
    border: medium solid #ffffff;
	border-width: 10px 0px 10px 10px;
	border-color: transparent transparent transparent #ffffff ;
    display: inline-block;
}

.DropdownTitle{
    width: 95%;
    display: inline-block;
}

@media screen and (max-width: 990px){
    .DropdownTile {
        margin: 1rem 0rem;
    }
}
