.DescriptionSection{
    text-align: center;
    margin: 0 10%;
    color: white;
    height: 100%;
    display:flex;
    flex-direction: column;
    border-radius: 1rem;
    width: 70%;
   justify-content: center;
    
}

.DescriptionSection a:hover{
    color: white;
}

@media (max-width: 990px) {
    .DescriptionSection {
        display: block;
        width: 100%;
        overflow: hidden;
        margin: 0;
    }
    .DescriptionSection a{
        display: flex;
    }
  }