.Header{
    position: fixed;
    width: 100%;
    z-index : 9999;
    background-color: #0e1b2b;
}

.Navigation img{
    width: 2.5%;
    padding: 0;
    margin-left: 1%;
}

@media (max-width: 990px) {
    .Navigation img{
        width: 4%;
        padding: 0;
        margin-left: 1%;
    }
  }

  @media (max-width: 650px) {
    .Navigation img{
        width: 7%;
        padding: 0;
        margin-left: 1%;
    }
  }

@media (max-width: 400px) {
    .Navigation img{
        width: 10%;
        padding: 0;
        margin-left: 1%;
    }
  }
/*

.Title img{
    width: 10%;
}


.Links-container{
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: space-between;
}
.Links-container *{
    display: inline;
    flex: 1;
} */